import React from "react";
import Banner from "../../components/Banner/Banner";
import AboutUsSection1 from "./items/AboutUsSection1";
import AboutUsSection2 from "./items/AboutUsSection2";
import AboutUsSection3 from "./items/AboutUsSection3";
import { useData } from "../../services/DataProvider";

const AboutUs = () => {
  const { data } = useData();

  return (
    <main className="bg-gray-50 text-gray-900">
      {/* Hero Section */}
      <Banner
        gradient={
          "bg-gradient-to-r from-orange-400 via-amber-400 to-yellow-400"
        }
        text1={data.about.banner.title}
        text2={data.about.banner.description}
      />

      {/* Our History Section */}
      <AboutUsSection1 />

      {/* Partners Section */}
      <AboutUsSection2 />

      {/* Call to Action Section */}
      <AboutUsSection3 />
    </main>
  );
};

export default AboutUs;
