import React from "react";
import { motion, useInView } from "framer-motion";
import SectionTitle from "../../../components/SectionTitle";
import { useData } from "../../../services/DataProvider";

const ContactInfo = () => {
  const { data } = useData();

  const emailRef = React.useRef(null);
  const phoneRef = React.useRef(null);
  const timeOpenRef = React.useRef(null);
  const addressRef = React.useRef(null);

  const emailInView = useInView(emailRef, {
    once: true,
    margin: "-10% 0px 0% 0px",
  });
  const phoneInView = useInView(phoneRef, {
    once: true,
    margin: "-10% 0px 0% 0px",
  });
  const timeOpenInView = useInView(phoneRef, {
    once: true,
    margin: "-10% 0px 0% 0px",
  });
  const addressInView = useInView(addressRef, {
    once: true,
    margin: "-10% 0px 0% 0px",
  });

  return (
    <section className="py-16 px-4 md:px-8 bg-white text-gray-800">
      <SectionTitle
        title={data.contact.content.section1.title}
        description={data.contact.content.section1.description}
      />

      <div className="flex flex-col items-center">
        <motion.div
          ref={emailRef}
          className="bg-gray-100 p-6 rounded-lg shadow-lg mb-8 w-full md:w-1/2 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: emailInView ? 1 : 0, y: emailInView ? 0 : 20 }}
          transition={{ duration: 0.5, delay: 0.2 }}
        >
          <h3 className="text-xl font-semibold mb-4 text-center">
            {data.contact.content.section1.gridItems.email.title}
          </h3>
          <p>
            <a
              href={`mailto:${data.contact.content.section1.gridItems.email.address1}`}
              className="text-sky-500 hover:text-sky-600"
            >
              {data.contact.content.section1.gridItems.email.address1}
            </a>
          </p>
          <p className="mt-1">
            <a
              href={`mailto:${data.contact.content.section1.gridItems.email.address2}`}
              className="text-sky-500 hover:text-sky-600"
            >
              {data.contact.content.section1.gridItems.email.address2}
            </a>
          </p>
        </motion.div>

        <motion.div
          ref={phoneRef}
          className="bg-gray-100 p-6 rounded-lg shadow-lg mb-8 w-full md:w-1/2"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: phoneInView ? 1 : 0, y: phoneInView ? 0 : 20 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <h3 className="text-xl font-semibold mb-4 text-center">
            {data.contact.content.section1.gridItems.phone.title}
          </h3>
          <p className="text-center">
            <a
              href={`tel:${data.contact.content.section1.gridItems.phone.number.unformatted}`}
              className="text-sky-500 hover:text-sky-600"
            >
              {data.contact.content.section1.gridItems.phone.number.formatted}
            </a>
          </p>
        </motion.div>

        <motion.div
          ref={timeOpenRef}
          className="bg-gray-100 p-6 rounded-lg shadow-lg mb-8 w-full md:w-1/2"
          initial={{ opacity: 0, y: 20 }}
          animate={{
            opacity: timeOpenInView ? 1 : 0,
            y: timeOpenInView ? 0 : 20,
          }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          <h3 className="text-xl font-semibold mb-4 text-center">
            {data.contact.content.section1.gridItems.timeOpen.title}
          </h3>

          <div className="mb-5 text-center">
            <p>
              {data.contact.content.section1.gridItems.timeOpen.line1}
              <br />
              {data.contact.content.section1.gridItems.timeOpen.line2}
              <br />
              {data.contact.content.section1.gridItems.timeOpen.line3}
            </p>
          </div>
        </motion.div>

        <motion.div
          ref={addressRef}
          className="bg-gray-100 p-6 rounded-lg shadow-lg w-full md:w-3/4 lg:w-1/2 mx-auto"
          initial={{ opacity: 0, y: 20 }}
          animate={{
            opacity: addressInView ? 1 : 0,
            y: addressInView ? 0 : 20,
          }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <h3 className="text-xl font-semibold mb-4 text-center">
            {data.contact.content.section1.gridItems.address.title}
          </h3>

          <div className="mb-5 text-center">
            <p>
              {data.contact.content.section1.gridItems.address.location.line1}
              <br />
              {data.contact.content.section1.gridItems.address.location.line2}
              <br />
              {data.contact.content.section1.gridItems.address.location.line3}
            </p>
          </div>

          <div className="overflow-hidden rounded-lg shadow-md">
            <iframe
              title={`${data.contact.content.section1.gridItems.address.location.maps.title}`}
              src={`${data.contact.content.section1.gridItems.address.location.maps.url}`}
              width="100%"
              height="450"
              className="border-0"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            />
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ContactInfo;
