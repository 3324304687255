import React from "react";

const VirtualWalk = () => {
  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <iframe
        src="https://tourmkr.com/F1j9PUoj6R/38150685p&265.31h&79.64t"
        width="100%"
        height="100%"
        allowFullScreen
        className="border-none"
        title="Virtual Tour"
      ></iframe>
    </div>
  );
};

export default VirtualWalk;
