import React from "react";
import SectionTitle from "../../../components/SectionTitle";
import { motion } from "framer-motion";
import { useData } from "../../../services/DataProvider";

const AboutUsSection1 = () => {
  const { data } = useData();

  return (
    <section className="py-16 px-4 md:px-8 bg-white text-gray-800">
      <SectionTitle title={data.about.content.section1.title} />

      <motion.p
        className="text-base md:text-lg lg:text-xl leading-relaxed mb-8 md:mb-10 text-center md:text-left max-w-4xl mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.2 }}
      >
        {data.about.content.section1.text1}
        <br /> <br />
        {data.about.content.section1.text2}
        <br /> <br />
        {data.about.content.section1.text3}
        <br /> <br />
        {data.about.content.section1.text4}
        <br /> <br />
        {data.about.content.section1.text5}
      </motion.p>
    </section>
  );
};

export default AboutUsSection1;
