import React from "react";
import Select from "react-select";
import { useLanguage } from "../../services/DataProvider";

const LanguageSwitcher = ({ isMobile }) => {
  const { language, changeLanguage } = useLanguage();

  const languageOptions = [
    { value: "hr", label: "Hrvatski" },
    { value: "en", label: "English" },
  ];

  const handleChange = (selectedOption) => {
    changeLanguage(selectedOption.value);
  };

  return (
    <div className={`flex items-center justify-center space-x-2 text-gray-400`}>
      {isMobile ? <></> : <div className="border-r border-gray-700 h-6"></div>}

      <Select
        options={languageOptions}
        value={languageOptions.find((option) => option.value === language)}
        onChange={handleChange}
        className="w-30 pl-1"
        classNamePrefix="react-select"
        isSearchable={false}
        styles={{
          container: (provided) => ({
            ...provided,
          }),
          control: (provided) => ({
            ...provided,
            backgroundColor: "#172554",
            borderRadius: "0.5rem",
            border: "none",
            minHeight: "2.5rem",
            cursor: "pointer",
            userSelect: "none",
          }),
          menu: (provided) => ({
            ...provided,
            backgroundColor: "#1f2937",
            borderRadius: "0.5rem",
            marginTop: "0.25rem",
          }),
          option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
              ? "#2d3748"
              : state.isFocused
              ? "#4a5568"
              : "transparent",
            borderRadius: "0.5rem",
            color: "#9ca3af",
            cursor: "pointer",
            userSelect: "none",
          }),
          singleValue: (provided) => ({
            ...provided,
            color: "#9ca3af",
          }),
          indicatorSeparator: () => ({
            display: "none",
          }),
          dropdownIndicator: (provided) => ({
            ...provided,
            color: "#9ca3af",
          }),
        }}
      />
    </div>
  );
};

export default LanguageSwitcher;
