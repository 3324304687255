import React from "react";
import Banner from "../../components/Banner/Banner";
import { useData } from "../../services/DataProvider";

const Home = () => {
  const { data } = useData();

  return (
    <main className="bg-gray-50 text-gray-900">
      {/* Hero Section */}
      <Banner
        gradient={"bg-gradient-to-b from-red-600 from-30% to-violet-800 to-65%"}
        text1={data.home.banner.title}
        text2={data.home.banner.description}
        buttonLink1={`${data.home.banner.button1.link}`}
        buttonText1={`${data.home.banner.button1.text}`}
        buttonLink2={`${data.home.banner.button2.link}`}
        buttonText2={`${data.home.banner.button2.text}`}
        fullSize={true}
      />
    </main>
  );
};

export default Home;
