import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Canvas } from "@react-three/fiber";
import InteractiveBackground from "./InteractiveBackground";

const Banner = ({
  gradient,
  text1,
  text2,
  buttonLink1,
  buttonText1,
  buttonLink2,
  buttonText2,
  interactive,
  fullSize,
}) => {
  return (
    <section
      className={`relative ${
        interactive || fullSize ? "h-[90vh]" : "h-[70vh]"
      } ${gradient} text-white flex items-center justify-center overflow-hidden`}
      style={{
        backgroundImage: `url('/images/antolic-ducan.jpg')`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {interactive && (
        <Canvas
          className="absolute inset-0 w-full h-full z-0 pointer-events-none"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
        >
          <ambientLight intensity={0.5} />
          <pointLight position={[10, 10, 10]} />
          <InteractiveBackground />
        </Canvas>
      )}
      <div
        className={`relative z-10 text-center pt-5 ${
          fullSize ? "pb-4" : "pb-2"
        } px-4 w-full flex flex-col items-center justify-center bg-black/70`}
      >
        <motion.h1
          className="text-5xl font-extrabold leading-tight mb-4"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {text1}
        </motion.h1>
        <motion.p
          className="text-lg md:text-xl mb-6"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.3 }}
        >
          {text2}
        </motion.p>

        <div className="flex space-x-4">
          {buttonText1 && (
            <Link to={`${buttonLink1}`}>
              <motion.button
                className="px-6 py-3 bg-sky-500 hover:bg-sky-600 text-white font-semibold rounded-lg shadow-lg transition duration-300 transform hover:scale-105 text-sm"
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
              >
                {buttonText1}
              </motion.button>
            </Link>
          )}

          {buttonText2 && (
            <Link to={`${buttonLink2}`}>
              <motion.button
                className="px-6 py-3 bg-sky-500 hover:bg-sky-600 text-white font-semibold rounded-lg shadow-lg transition duration-300 transform hover:scale-105 text-sm"
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                transition={{ duration: 0.3 }}
              >
                {buttonText2}
              </motion.button>
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

export default Banner;
