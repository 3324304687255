import React from "react";
import { motion, useInView } from "framer-motion";
import SectionTitle from "../../../components/SectionTitle";
import { useData } from "../../../services/DataProvider";

const importImages = (r) => r.keys().map(r);

const imagesPartners = importImages(
  require.context(
    "/public/images/portfolio/partners",
    false,
    /\.(png|jpe?g|svg)$/
  )
).sort((a, b) => {
  const nameA = a.split("/").pop().toUpperCase();
  const nameB = b.split("/").pop().toUpperCase();
  return nameA.localeCompare(nameB);
});

const createImageAltText = (name) => {
  const baseName = name.split("/").pop().split(".")[0]; // Remove any URL parts if present
  return baseName
    .split(/[_]/)
    .map((word) => word.charAt(0) + word.slice(1))
    .join(" ");
};

const ProductsSection2 = () => {
  const { data } = useData();

  const refPartners = React.useRef(null);
  const isPartnersInView = useInView(refPartners, { once: true });

  return (
    <section className="py-16 px-2">
      {/* Partners */}
      <SectionTitle
        title={data.products.content.section2.partners.title}
        description={data.products.content.section2.partners.description}
      />

      <div
        className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 px-4 pb-16"
        ref={refPartners}
      >
        {imagesPartners.map((image, index) => {
          const isBase64 = image.startsWith("data:image/");
          const imageAltText = isBase64
            ? `Image-${index}` // Use a generic name if base64
            : createImageAltText(image);

          return (
            <motion.div
              key={index}
              className="bg-white p-4 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300 flex items-center"
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: isPartnersInView ? 1 : 0,
                y: isPartnersInView ? 0 : 20,
              }}
              transition={{ duration: 1, delay: index * 0.1 }}
            >
              <img
                src={image}
                alt={imageAltText}
                className="h-auto max-h-20 w-full object-contain mx-auto"
                loading="lazy"
              />
            </motion.div>
          );
        })}
      </div>
    </section>
  );
};

export default ProductsSection2;
