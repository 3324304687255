import React from "react";
import SectionTitle from "../../../components/SectionTitle";
import { motion, useInView } from "framer-motion";
import GridItem from "../../../components/GridItem";
import { useData } from "../../../services/DataProvider";

const ProductsSection1 = () => {
  const { data } = useData();
  const ref = React.useRef(null);
  const inView = useInView(ref, { once: true, margin: "90% 0px 90% 0px" });

  return (
    <section className="py-16 px-4 md:px-8 bg-white text-gray-800">
      <SectionTitle title={data.products.content.section1.title} />

      <motion.div
        ref={ref}
        initial={{ opacity: 0 }}
        animate={{ opacity: inView ? 1 : 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <p className="text-l md:text-2xl font-semibold mb-6 text-center">
          {data.products.content.section1.subtitle}
        </p>
        <div className="grid md:grid-cols-3 gap-8">
          {data.products.content.section1.gridItems.map((item, index) => (
            <GridItem
              key={index}
              title={item.title}
              color={"bg-gray-200 text-center"}
            />
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default ProductsSection1;
