import React, { createContext, useContext, useState, useEffect } from "react";
import { useCookies } from "react-cookie";

const DataContext = createContext();
const LanguageContext = createContext();

export const useData = () => useContext(DataContext);
export const useLanguage = () => useContext(LanguageContext);

export const DataProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(["language"]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [language, setLanguage] = useState(cookies.language || "hr");

  const fetchData = async (lang) => {
    setLoading(true);
    setError(false);
    try {
      const response = await fetch(`/shared/data_${lang}.json`);
      const result = await response.json();
      setData(result);
    } catch (error) {
      console.error("Failed to fetch data", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(language);
  }, [language]);

  const changeLanguage = (lang) => {
    setLanguage(lang);
    setCookie("language", lang, { path: "/" });
  };

  return (
    <DataContext.Provider value={{ data, loading, error }}>
      <LanguageContext.Provider value={{ language, changeLanguage }}>
        {children}
      </LanguageContext.Provider>
    </DataContext.Provider>
  );
};
