import React from "react";
import { useData } from "../services/DataProvider";

const Footer = () => {
  const { data } = useData();

  return (
    <footer className="bg-blue-950 text-gray-200 py-5 border-t border-gray-700">
      <div className="container mx-auto flex flex-col items-center">
        {/* Social media */}
        <div className="flex space-x-6 mb-4">
          <a
            href={`${data.footer.socialMedia.facebook}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-200 hover:text-gray-400 transition duration-300"
            aria-label="Facebook"
          >
            <i className="fa-brands fa-facebook-f fa-lg hover-opacity"></i>
          </a>

          <a
            href={`${data.footer.socialMedia.tourmkr}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-200 hover:text-gray-400 transition duration-300"
            aria-label="Virtual Walk"
          >
            <i className="fa-solid fa-person-walking fa-lg hover-opacity"></i>
          </a>

          <a
            href={`${data.footer.socialMedia.instagram}`}
            target="_blank"
            rel="noopener noreferrer"
            className="text-gray-200 hover:text-gray-400 transition duration-300"
            aria-label="Instagram"
          >
            <i className="fa-brands fa-instagram fa-lg hover-opacity"></i>
          </a>
        </div>

        {/* Credits */}
        <p className="text-gray-400 text-xs md:text-sm">
          {data.footer.credits}
        </p>
      </div>
    </footer>
  );
};

export default Footer;
