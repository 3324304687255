import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { useData } from "../../services/DataProvider";
import LanguageSwitcher from "./LanguageSwitcher";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const { data } = useData();

  const menuOptions = data.menu;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <header className="fixed top-0 left-0 w-full bg-blue-950 shadow-md text-white z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            {/* Logo */}
            <Link to="/" className="flex items-center select-none">
              <motion.img
                src="/images/logo.png"
                alt="Company Logo"
                className="h-10 md:h-14 w-auto"
                initial={{ scale: 1 }}
                whileHover={{ scale: 1.1 }}
                transition={{ duration: 0.3 }}
              />
            </Link>

            {/* Desktop Menu */}
            <div className="hidden md:flex items-center space-x-6">
              {Object.entries(menuOptions).map(([key, value]) => (
                <Link
                  key={key}
                  to={key === "home" ? "/" : `/${key.toLowerCase()}`}
                  className={`text-base md:text-lg font-semibold select-none transition duration-300 ${
                    location.pathname ===
                    (key === "home" ? "/" : `/${key.toLowerCase()}`)
                      ? "text-sky-300"
                      : "text-white hover:text-sky-300"
                  }`}
                >
                  {value}
                </Link>
              ))}
              {/* Language Switcher */}
              <LanguageSwitcher />
            </div>

            {/* Mobile Menu button */}
            <div className="-mr-2 flex md:hidden">
              <button
                onClick={toggleMenu}
                type="button"
                className="bg-blue-950 inline-flex items-center justify-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                aria-controls="mobile-menu"
                aria-expanded="false"
              >
                <span className="sr-only">Open menu</span>
                {!isOpen ? (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                ) : (
                  <svg
                    className="block h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile Menu content */}
        <div
          className={`${isOpen ? "block" : "hidden"} md:hidden`}
          id="mobile-menu"
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {Object.entries(menuOptions).map(([key, value]) => (
              <Link
                key={key}
                to={key === "home" ? "/" : `/${key.toLowerCase()}`}
                className={`text-center text-xl font-semibold select-none py-2 block transition duration-300 ${
                  location.pathname ===
                  (key === "home" ? "/" : `/${key.toLowerCase()}`)
                    ? "text-sky-300"
                    : "text-white hover:text-sky-300"
                }`}
                onClick={() => setIsOpen(!isOpen)}
              >
                {value}
              </Link>
            ))}
            {/* Mobile Language Switcher */}
            <div className="border-t border-gray-700 pt-2">
              <LanguageSwitcher isMobile={true} />
            </div>
          </div>
        </div>
      </header>

      {/* Account for fixed header height */}
      <div className="pt-16"></div>
    </>
  );
};

export default Header;
