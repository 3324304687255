import React from "react";
import SectionTitle from "../../../components/SectionTitle";
import { motion } from "framer-motion";
import { useData } from "../../../services/DataProvider";

const AboutUsSection2 = () => {
  const { data } = useData();

  return (
    <section className="py-16 px-4 md:px-8 bg-gray-50 text-gray-800">
      <SectionTitle title={data.about.content.section2.title} />

      <motion.p
        className="text-gray-500 text-base text-center md:text-lg lg:text-xl max-w-4xl mb-4 mx-auto"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.2 }}
      >
        {data.about.content.section2.fullName}
        {" / "}
        {data.about.content.section2.address}
        {" / "}
        {data.about.content.section2.court}
        {" / "}
        {data.about.content.section2.oib}
        {" / "}
        {data.about.content.section2.maticni}
        {" / "}
        {data.about.content.section2.equity}
        {" / "}
        {data.about.content.section2.bankAccounts.title}{" "}
        {data.about.content.section2.bankAccounts.bank1}
        {" • "}
        {data.about.content.section2.bankAccounts.bank2}
        {" • "}
        {data.about.content.section2.bankAccounts.bank3}
        {" • "}
        {data.about.content.section2.bankAccounts.bank4}
        {" / "}
        {data.about.content.section2.director}
      </motion.p>
    </section>
  );
};

export default AboutUsSection2;
